import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const Pega = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Pega"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container>
                        {/* <h6 className="mt-5">Designing experiences that Engage, Convert, and deliver Results.</h6> */}
                        <p className="mt-5">TEQT is a proud Pega Global Elite Partner providing Pega implementation, delivery and testing services as well as deep industry expertise. Pega’s Digital Process Automation and BPM software helps global enterprises crush business complexity and operate smarter, simpler, and faster. Our 1000+ strong Pega practice brings unmatched expertise that assists global organizations to:</p>

                        <ul className="circle-list">
                            <li className="mt-2">Achieve their digital process automation goals</li>
                            <li className="mt-2">Revolutionize the customer experience</li>
                            <li className="mt-2">Boost efficiency</li>
                            <li className="mt-2"> Maximize customer lifetime value.</li>
                        </ul>

                        {/* <h6 className="mt-3">Engineering, Domain Expertise – Key ingredients to successful Metaverse implementations</h6> */}
                        <p className="mt-3">With 115+ Pega Lead System Architects onboard, we are one of the highest certified Pega practices in the world. Our highly experienced teams have delivered 300+ successful customer implementations. With a suite of Pega Platform productivity solutions, we deliver signiﬁcant value to customers in Insurance, Banking & Financial Services, Government, Manufacturing, Healthcare and Life Sciences, Travel, Transportation and Hospitality.</p>
                        <p className="mt-3">Through our unique and combined expertise in the Pega Platform, we provide CRM, Case Management, RPA, and Decisioning solutions that transform organizations into the digital businesses of tomorrow.</p>

                        <h6>Our Pega Solutions:</h6>

                        <ul className="circle-list mt-3">
                            <li className="mt-2">Insurance Smart Quote</li>
                            <li className="mt-2">Pega AI Chatbot</li>
                            <li className="mt-2">SMB Assistance Loans</li>
                            <li className="mt-2">Authorization Rules Engine (ARC), and</li>
                        </ul>
                        <h6 className="mt-3">Our accelerators:</h6>
                        <ul className="circle-list mt-3">
                            <li className="mt-2">Virtual Lead System Architect</li>
                            <li className="mt-2">Pega DevOps have been delivering great business results to our customers.</li>
                        </ul>
                        <p className="mt-2 mb-5">Our deep expertise in Digital Process Automation technologies such as low-code/no-code platforms, coupled with our strength in domain empowers organizations to accelerate growth, transform consumer experience, and create lasting value.</p>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query PegaPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

Pega.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Pega;
